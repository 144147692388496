<!-- external -->
@if (external()) {
	<a
		[attr.href]="link()"
		target="_blank"
		rel="noopener sponsored"
		class="relative block w-full h-64"
		[ngClass]="customHeight()"
		(click)="cardClick.emit(true)"
	>
		<img
			[ngSrc]="image() | cdn"
			fill
			[attr.alt]="'Image of ' + title()"
			class="object-cover pointer-events-none rounded-2xl"
		/>
	</a>
} @else {
	<!-- internal -->
	<a
		[routerLink]="link()"
		[queryParams]="uni()"
		queryParamsHandling="merge"
		class="relative block w-full h-64"
		[ngClass]="customHeight"
		(click)="cardClick.emit(true)"
	>
		<img
			[ngSrc]="image() | cdn"
			fill
			alt=""
			class="object-cover pointer-events-none rounded-2xl"
		/>
	</a>
}

<h3 class="mt-4 text-lg font-bold text-gray-900">
	@if (external()) {
		<a
			[attr.href]="link()"
			target="_blank"
			rel="noopener sponsored"
			(click)="cardClick.emit(true)"
			>{{ title() }}</a
		>
	} @else {
		<a
			[routerLink]="link()"
			[queryParams]="uni()"
			(click)="cardClick.emit(true)"
			queryParamsHandling="merge"
		>
			{{ title() }}
		</a>
	}
</h3>

<p class="mt-2 text-base text-gray-500">
	{{ desc() }}
</p>
