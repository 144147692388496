<button
	id="load-more"
	(click)="onLoadMore()"
	class="w-full !pt-3 inline-flex items-center justify-center text-lg font-medium transition-all border rounded-md secondary-button hover:shadow-lg focus:outline-none"
>
	@if (!isLoading()) {
		<span class="inline-flex items-center justify-center">
			<uc-refresh-svg />
			<span>Load more</span>
		</span>
	} @else {
		<uc-loading-state-btn class="h-5" buttonText="Loading..." />
	}
</button>
