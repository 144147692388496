<div class="border rounded-lg">
	<div class="mx-4 mt-6 border-b md:pb-6 md:mx-6 md:grid md:grid-cols-2">
		<div class="leading-6 md:col-span-1">
			<p class="text-lg font-semibold text-gray-900">
				{{ heading() }}
			</p>
			<p class="text-gray-500">Showing {{ resultCount() }} {{ resultType() }}</p>
		</div>
		<div class="flex items-center mt-4 mb-6 md:col-span-1 md:mb-0 md:mt-0">
			<form class="relative w-full">
				<div
					class="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3"
				>
					<uc-search-svg customStyles="w-4 h-4 text-gray-500" />
				</div>
				<label for="table-search-input" class="sr-only">{{
					placeholder()
				}}</label>
				<input
					id="table-search-input"
					#searchSubject
					(input)="onSearch.emit(searchSubject.value)"
					type="search"
					class="w-full pl-10 text-gray-500 border-gray-300 rounded-lg bg-gray-50 filter-overlay-search"
					[attr.placeholder]="placeholder()"
					autocomplete="off"
				/>
			</form>
		</div>
	</div>
	@if (resultCount() > 0) {
		<ng-content></ng-content>
	} @else {
		<div class="flex flex-col items-center justify-center my-8 md:my-12">
			<div class="p-3 mb-5 rounded-lg bg-uc-red-100">
				<uc-exclamation-mark-triangular-svg />
			</div>
			<div class="text-center">
				<p class="mb-2 text-lg font-semibold leading-6 text-gray-900">
					{{ errorTitle() }}
				</p>
				<p class="text-sm leading-5 text-gray-500">
					{{ errorDescription() }}
				</p>
			</div>
		</div>
	}
</div>
