import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * matches university clearing profile page
 * e.g. /universities/coventry-university/courses/some-course-name-here
 */
const courseProfileRegex = /\/universities\/[a-z-?]+\/courses\/[a-z0-9-?=?]+/gi;
/**
 * matches university clearing profile page
 * e.g. /universities/coventry-university/clearing
 */
const uniProfileRegex = /\/universities\/[a-z-?]+(\/(clearing|postgraduate|reviews))?/gi;
/**
 * matches course search pages:
 * courses/undergraduate || courses/postgraduate || courses/clearing
 */
const courseSearchRegex =
	/\/courses\/(degrees|search|clearing|undergraduate|postgraduate)/gi;
const uniSearchRegex = /\/universities(\/clearing)?/;
const dedicatedClearingPage = '/clearing';
const searchAndDiscoverPage = '/courses';

@Injectable({
	providedIn: 'root',
})
export class PreviousPageBtnService {
	readonly appLink = 'https://onelink.to/unicompare';
	private _previousPath = new BehaviorSubject<string>(this.appLink);
	previousPath$ = this._previousPath.asObservable();

	private readonly _staticPathsToTrack = [
		'/',
		dedicatedClearingPage,
		searchAndDiscoverPage,
	];

	private readonly _regexForPathsToTrack = [
		courseProfileRegex,
		uniProfileRegex,
		courseSearchRegex,
		uniSearchRegex,
	];

	setPreviousPath(path: string): void {
		this._previousPath.next(path);
	}

	/**
	 * @param url url of the page user was on before visiting the register interest page
	 * @returns the name of the page based on the url
	 * please note: order of statements here very important
	 */
	getPageTitle(url: string) {
		if (url === this.appLink) {
			return 'Download our app';
		} else if (url === dedicatedClearingPage) {
			return `Clearing ${new Date().getFullYear()}`;
		} else if (url === searchAndDiscoverPage) {
			return 'Search & Discover';
		} else if (url.match(courseProfileRegex)) {
			return 'Course';
		} else if (url.includes('/universities/clearing')) {
			return 'University Search';
		} else if (url.match(uniProfileRegex)) {
			return 'University';
		} else if (url.match(courseSearchRegex)) {
			return 'Course Search';
		} else if (url.match(uniSearchRegex)) {
			return 'University Search';
		} else {
			return 'Home';
		}
	}

	determineIfPathShouldBeTracked(url: string) {
		const lowercaseUrl = url.toLowerCase();

		if (this._staticPathsToTrack.includes(url)) return true;

		for (let i = 0; i < this._regexForPathsToTrack.length; i++) {
			if (lowercaseUrl.match(this._regexForPathsToTrack[i])) {
				return true;
			}
		}

		return false;
	}

	/**
	 * @param url absolute path returned by this service
	 * @returns object in format { path, params}
	 * path - base path e.g. /universities/coventry-university/courses/some-course-name-here
	 * params - query params e.g. { course: 'some-course-name-here' }
	 */
	getQueryParams(url: string) {
		const [path, paramsStr] = url.split('?');

		if (!paramsStr) {
			return { path, params: null };
		} else {
			const queryParams = paramsStr.split('&');
			const params = queryParams.reduce((acc, curr) => {
				const [key, value] = curr.split('=');
				return { ...acc, [key]: decodeURIComponent(value) };
			}, {});

			return { path, params };
		}
	}
}
