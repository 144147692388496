import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CourseGTMService } from '@uc/web/core';
import { Product } from '@uc/web/shared/data-models';
import { CommonModule } from '@angular/common';
import { CustomCookieService } from '@uc/shared/authentication';

@Component({
	selector: 'uc-visit-website-keyword-btn',
	standalone: true,
	templateUrl: './visit-website-keyword-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class VisitWebsiteKeywordBtnComponent {
	externalUrl = input.required<string | undefined>();
	subject = input.required<string>();
	buttonText = input('Visit Website');
	customStyles = input('w-auto');
	product = input<Product | ''>('');
	componentName = input.required<string>();
	uniId = input.required<number | null>();
	uniName = input.required<string>();

	userAuthenticated = this._customCookieSrv.get('user_id') ? true : false;

	constructor(
		private _courseGTMSrv: CourseGTMService,
		private _customCookieSrv: CustomCookieService,
	) {}

	onKeywordClick() {
		const uniId = this.uniId();

		if (uniId) {
			this._courseGTMSrv.keywordClick(
				this.componentName(),
				uniId,
				this.uniName(),
				this.userAuthenticated,
				this.subject(),
				this.product(),
			);
		}
	}
}
