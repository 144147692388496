import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnInit,
	input,
	model,
	output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownDirective, Environment } from '@uc/utilities';
import { Product, ProductEnum } from '@uc/web/shared/data-models';
import {
	SearchSvgComponent,
	ArrowRightSvgComponent,
	ArrowDownSvgComponent,
} from '@uc/shared/svg';
import { LevelSearchBarParams } from './search-bar-with-study-level.models';

@Component({
	selector: 'uc-search-bar-with-study-level',
	standalone: true,
	templateUrl: './search-bar-with-study-level.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		DropdownDirective,
		FormsModule,
		SearchSvgComponent,
		ArrowRightSvgComponent,
		ArrowDownSvgComponent,
	],
})
export class SearchBarWithStudyLevelComponent implements OnInit {
	selectedProduct = model.required<Product>();
	searchValue = model('');
	placeholder = input('Search');
	showClearingInDropdown = input(true);
	clearingEnabled!: boolean;
	products: Product[] = [ProductEnum.Undergraduate, ProductEnum.Postgraduate];

	navigate = output<LevelSearchBarParams>();

	constructor(@Inject('environment') private _environment: Environment) {
		this.clearingEnabled = this._environment.clearingEnabled;
	}

	ngOnInit(): void {
		if (this.clearingEnabled && this.showClearingInDropdown()) {
			this.products.unshift(ProductEnum.Clearing);
			this.selectedProduct.set(ProductEnum.Clearing);
		}
	}

	onChangeStudyType(product: Product): void {
		this.selectedProduct.set(product);
	}

	navigateToPage(): void {
		const params = {
			product: this.selectedProduct(),
			searchTerm: this.searchValue(),
		};
		this.navigate.emit(params);
	}
}
