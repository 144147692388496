<section class="p-12 text-center border border-gray-200 rounded-lg">
	<h2 class="text-xl font-bold">{{ title }}</h2>
	<p class="mt-3 text-gray-500">{{ copy }}</p>
	<div class="flex flex-col flex-wrap justify-center gap-4 mt-4 sm:flex-row">
		<uc-primary-blue-btn
			link="/register"
			customStyles="w-full sm:w-auto"
			buttonText="Create An Account"
		></uc-primary-blue-btn>
		<uc-download-app-btn
			product="clearing"
			link="https://onelink.to/clearing"
			customStyles="primary-green-btn"
			buttonText="Download Our Clearing App"
		></uc-download-app-btn>
		<uc-secondary-blue-btn
			[link]="searchBtnLink()"
			customStyles="w-full sm:w-auto"
			[buttonText]="'Search ' + searchBtnType()"
		></uc-secondary-blue-btn>
	</div>
</section>
