<ul
	(scroll)="onScroll()"
	tabindex="0"
	class="overflow-y-auto overscroll-contain h-[calc(100dvh-134px)] md:overflow-y-hidden md:h-auto pb-8 sm:pb-0"
>
	@for (
		suggestion of suggestions() | slice: 0 : maxSuggestions();
		track suggestion;
		let i = $index;
		let last = $last
	) {
		<li
			#autosuggestItem
			tabindex="0"
			class="px-3 pt-2 cursor-pointer focus:outline-none"
			[ngClass]="{ 'bg-[#E0F2FE] text-uc-blue-900': focusedIndex() === i }"
			(keydown)="handleKeyboardEvent($event)"
			(hover)="focusedIndex.set(i)"
			(mouseover)="focusedIndex.set(i)"
			(mouseout)="focusedIndex.set(-1)"
			(click)="setItem(suggestion.link)"
		>
			<div
				class="flex justify-between pb-2 text-base text-gray-900 md:border-0 md:text-sm"
				[ngClass]="{ 'border-b': !last }"
			>
				<p class="max-w-full mr-1 truncate first-letter:uppercase">
					{{ suggestion.name }}
					<span
						class="block text-gray-500 truncate sm:inline"
						[ngClass]="{ 'text-uc-blue-900': focusedIndex() === i }"
					>
						<span class="hidden sm:inline">(</span
						>{{ suggestion.courses_count }}
						{{ suggestion.courses_count > 1 ? 'courses' : 'course' }}
						<span class="hidden -ml-0.5 sm:inline">)</span>
					</span>
				</p>
				<uc-arrow-right-v2-svg class="self-center inline sm:hidden" />
			</div>
		</li>
	}
</ul>
