import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	ElementRef,
	HostListener,
	Inject,
	input,
	model,
	OnDestroy,
	OnInit,
	output,
	PLATFORM_ID,
	signal,
	viewChild,
} from '@angular/core';
import { CloseModalSvgComponent, SearchSvgComponent } from '@uc/shared/svg';
import { CourseSearchAutosuggestComponent } from '@uc/web/shared/feature/feature-course-search-autosuggest';
import { FormsModule } from '@angular/forms';
import { isPlatformBrowser, NgClass } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ScreenWidth } from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-course-finder',
	standalone: true,
	templateUrl: './course-finder.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgClass,
		FormsModule,
		SearchSvgComponent,
		CourseSearchAutosuggestComponent,
		CloseModalSvgComponent,
	],
})
export class CourseFinderComponent implements OnInit, AfterViewInit, OnDestroy {
	subject = input('', {
		transform: (value: string | undefined) => value?.replace(/-/g, ' ') || '',
	});

	resetSearchTerm = input<boolean>();
	showDegreeLevelOptions = input<boolean>(true);
	navigate = input<boolean>(false);
	context = input<'default' | 'courseSearchBar' | 'filter'>('default');
	errorPosition = input<'default' | 'bottom'>('default');
	disableError = input<boolean>(false);
	resetErrorOnBlur = input<boolean>(false);
	error = model<string>('');

	autosuggest = output<{ searchTerm: string; degree?: string }>();
	searchChange = output<string>();

	open = signal<boolean>(false);
	isMobile = signal(false);

	isCourseSearchbar = computed(() => this.context() === 'courseSearchBar');

	inputElem = viewChild<ElementRef<HTMLInputElement>>('input');
	containerElem = viewChild<ElementRef<HTMLDivElement>>('container');

	position: { top: number; left: number } | undefined;
	searchTerm = '';

	private _breakpointSub = this._breakpointObserver
		.observe(`(max-width: ${ScreenWidth.SM - 1}px)`)
		.subscribe((result) => this.isMobile.set(result.matches));

	@HostListener('window:scroll', ['$event']) onScroll() {
		if (this.context() !== 'filter') return;
		this.position = this.getElementPosition();
	}

	constructor(
		@Inject(PLATFORM_ID) private _platform: object,
		private _breakpointObserver: BreakpointObserver,
	) {
		effect(() => {
			if (this.isMobile() && this.open()) {
				setTimeout(() => window?.scrollTo(0, 0), 400);
			}
		});
	}

	ngOnInit(): void {
		this.searchTerm = this.subject() ?? '';
	}

	ngAfterViewInit() {
		if (this.context() !== 'filter') return;
		this.position = this.getElementPosition();
	}

	ngOnDestroy(): void {
		this._breakpointSub?.unsubscribe();
	}

	onEnter(autosuggest: { searchTerm: string; degree?: string }) {
		if (!autosuggest.searchTerm && !this.disableError()) {
			this.error.set('Please enter a subject');
			return;
		}

		const searchTerm = autosuggest.searchTerm.trim();
		this.open.set(false);
		this.autosuggest.emit({ ...autosuggest, searchTerm });
	}

	onSearch() {
		this.searchChange.emit(this.searchTerm.trim());

		if (this.searchTerm) {
			this.error.set('');
		}
	}

	onFocus() {
		this.open.set(true);
		if (this.isMobile()) {
			const container = this.containerElem()?.nativeElement;
			const options = { behavior: 'smooth', block: 'start' } as const;
			setTimeout(() => container?.scrollIntoView(options), 100);
			this.error.set('');
		}
	}

	onBlur() {
		if (this.resetErrorOnBlur()) {
			this.error.set('');
		}
	}

	onClose() {
		this.open.set(false);
		this.error.set('');
	}

	getElementPosition() {
		if (!isPlatformBrowser(this._platform)) return;

		const elem = this.inputElem()?.nativeElement.getBoundingClientRect();
		if (!elem) return;

		return {
			top: elem.top + elem.height,
			left: elem.left,
		};
	}
}
