<section class="py-8 bg-gray-800 sm:py-16">
	<div class="container flex flex-col text-white sm:items-center sm:text-center">
		<h2
			class="text-xl font-semibold font-xl sm:text-4xl"
			data-test-id="course-search-banner-heading"
		>
			{{ heading() }}
		</h2>
		<p class="pt-2 text-base text-gray-200 sm:pt-5 sm:text-xl">{{ description() }}</p>
		@if (isInAppBrowser()) {
			<uc-in-app-browser-search-bar class="w-full mt-8 sm:max-w-2xl" />
		} @else {
			<div class="w-full mt-8 sm:max-w-2xl" data-test-id="default-search-bar">
				<label for="search" class="sr-only">Search</label>

				<div class="relative sm:flex">
					<div class="relative w-full">
						<uc-search-svg
							class="absolute inset-y-0 left-0 flex items-center pl-6 pointer-events-none"
							customStyles="text-gray-400"
						/>
						<input
							class="w-full sm:rounded-r-none border-r-0 py-2.5 sm:py-4 px-4 pl-16 text-base text-gray-600 placeholder-gray-500 bg-white border border-gray-300 rounded-lg filter-overlay-search"
							type="search"
							[(ngModel)]="searchTerm"
							(keyup.enter)="onSearch()"
							placeholder="Search Courses"
							aria-label="Search"
							autocomplete="off"
						/>
					</div>

					<button
						(click)="onSearch()"
						class="flex items-center justify-center w-full px-6 py-2.5 mt-4 sm:mt-0 text-xl font-bold bg-white border-l-2 rounded-lg sm:rounded-l-none sm:flex sm:w-36 text-uc-blue-900 border-l-uc-blue-900 hover:bg-gray-100"
					>
						Search
						<uc-arrow-right-svg customStyles="w-4 h-4 sm:w-6 sm:h-6 ml-2.5" />
					</button>
				</div>
			</div>
		}
	</div>
</section>
