<div class="space-y-2 text-sm">
	<p class="font-medium" [ngClass]="labelDesign()">{{ fieldLabel() }}</p>
	<div class="grid grid-cols-2 overflow-hidden font-medium border divide-x rounded-md">
		<button
			(click)="onStudyLevel(undergraduate)"
			class="px-4 py-2 text-center"
			[ngClass]="{
				'text-white bg-blue-900':
					studyLevel() === undergraduate || studyLevel() === clearing,
			}"
		>
			Undergraduate
		</button>
		<button
			(click)="onStudyLevel(postgraduate)"
			class="px-4 py-2 text-center"
			[ngClass]="{
				'text-white bg-blue-900': studyLevel() === postgraduate,
			}"
		>
			Postgraduate
		</button>
	</div>
	<p class="text-gray-500">
		{{ description() }}
	</p>
</div>
