import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { SecondaryBlueBtnComponent } from '../../buttons/secondary-blue-btn/secondary-blue-btn.component';
import { DownloadAppBtnComponent } from '../../buttons/download-app-btn/download-app-btn.component';
import { PrimaryBlueBtnComponent } from '../../buttons/primary-blue-btn/primary-blue-btn.component';
import { ClearingService } from '@uc/utilities';

@Component({
	selector: 'uc-clearing-empty-state',
	standalone: true,
	templateUrl: './clearing-empty-state.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		PrimaryBlueBtnComponent,
		DownloadAppBtnComponent,
		SecondaryBlueBtnComponent,
	],
})
export class ClearingEmptyStateComponent implements OnInit {
	searchBtnType = input('Courses');
	searchBtnLink = input('/courses/degrees/undergraduate');

	currentYear = new Date().getFullYear();
	title = '';
	copy = '';

	constructor(private clearingSrv: ClearingService) {}

	ngOnInit(): void {
		if (this.clearingSrv.isPreClearing()) {
			this.title = `Clearing ${this.currentYear} Hasn’t Started Yet`;
			this.copy = `Clearing ${this.currentYear} has not opened yet. Check back in July to see which universities are offering Clearing courses for students.`;
		} else {
			this.title = `Clearing ${this.currentYear} is Now Over`;
			this.copy = `Clearing has now closed. Check back next year to find out which universities will be offering Clearing courses for ${
				this.currentYear + 1
			}.`;
		}
	}
}
