<section class="bg-white divider">
	<div class="flex flex-col">
		<div class="relative overflow-hidden">
			<!-- cover photo -->
			<div class="h-full md:flex">
				<a [routerLink]="slug()" class="md:flex">
					<uc-engage-card-cta-img
						backgroundImageClass="bg-cta-top-100"
						[border]="false"
					/>
				</a>
			</div>

			<div class="relative pt-6 mx-auto md:py-12 max-w-7xl">
				<div class="lg:px-5 md:ml-auto md:w-1/2 md:pl-8 lg:pl-16">
					<!-- icon -->
					<div class="flex items-center gap-3">
						<img
							[ngSrc]="'/assets/img/uc_sparkles.svg' | cdn"
							width="20"
							height="20"
							alt=""
						/>

						<p class="font-semibold text-gray-500 uppercase">
							SUBJECT RANKINGS
						</p>
					</div>

					<!-- title -->
					<p class="flex flex-wrap-reverse mt-2 tracking-tight">
						<a [routerLink]="slug()" class="md:flex">
							<span class="text-2xl font-bold md:text-3xl md:flex">
								{{ cardTitle() }}
							</span>
						</a>
					</p>

					<!-- description -->
					<p class="mt-4 text-base text-gray-500">
						{{ description() }}
					</p>

					<!-- CTA button -->
					<div class="mt-8">
						<a
							class="inline-block w-full md:w-auto blue-button"
							[routerLink]="slug()"
						>
							{{ buttonText() }}
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
