<div class="divider">
	<uc-heading [headingTitle]="'Begin Your Clearing ' + year + ' Search'" />
	<div class="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2 md:gap-16">
		@for (info of content; track info) {
			<uc-static-card
				[title]="info.title"
				[desc]="info.desc"
				[image]="info.image"
				[link]="info.link"
				[external]="info.external"
				[uni]="info.params"
				customHeight="lg:h-72"
			/>
		}
	</div>
</div>
