import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { LoadMoreBtnService } from './load-more-btn.service';
import { LoadingStateBtnComponent } from '../loading-state-btn/loading-state-btn.component';
import { RefreshSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-load-more-btn',
	standalone: true,
	templateUrl: './load-more-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [LoadingStateBtnComponent, RefreshSvgComponent],
})
export class LoadMoreBtnComponent {
	isLoading = input<boolean | null>(null);
	page = input<number>(1);
	searchTerm = input<string | null>(null);

	loadMore = output<void>();

	constructor(private _loadMoreSrv: LoadMoreBtnService) {}

	onLoadMore() {
		this._loadMoreSrv.nextPage(this.page() + 1);
		this.loadMore.emit();
	}
}
