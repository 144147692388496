import { CourseSuggestion } from '../models/course-search-autosuggest.models';

export const popularCourses: CourseSuggestion[] = [
	{ name: 'Accounting', courses_count: 529, link: 'accounting' },
	{ name: 'Business', courses_count: 4808, link: 'business' },
	{ name: 'Computer Science', courses_count: 1277, link: 'computer science' },
	{ name: 'Criminology', courses_count: 1374, link: 'criminology' },
	{ name: 'Economics', courses_count: 1770, link: 'economics' },
	{ name: 'Education', courses_count: 2078, link: 'education' },
	{ name: 'Engineering', courses_count: 5136, link: 'engineering' },
	{ name: 'Finance', courses_count: 1430, link: 'finance' },
	{ name: 'History', courses_count: 2949, link: 'history' },
	{ name: 'Law', courses_count: 1892, link: 'law' },
	{ name: 'Marketing', courses_count: 1273, link: 'marketing' },
	{ name: 'Medicine', courses_count: 293, link: 'medicine' },
	{ name: 'Nursing', courses_count: 1136, link: 'nursing' },
	{ name: 'Pharmacy', courses_count: 45, link: 'pharmacy' },
	{ name: 'Physiotherapy', courses_count: 95, link: 'physiotherapy' },
	{ name: 'Psychology', courses_count: 1976, link: 'psychology' },
];
