<div class="relative w-full">
	<div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
		<uc-search-svg customStyles="text-gray-400" />
	</div>
	<form [formGroup]="searchForm" class="w-full">
		<label for="search" class="sr-only">Search</label>
		<input
			#searchBox
			type="search"
			id="search"
			formControlName="search"
			[placeholder]="placeholderText"
			[value]="searchTerm"
			class="w-full px-4 py-2 pl-12 text-base text-gray-600 placeholder-gray-400 border border-gray-300 rounded-lg input-focus-within filter-overlay-search"
			aria-label="Search"
			autocomplete="off"
		/>
	</form>
</div>
